import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap";

import NavBar from "../components/NavBar";
import HomePage from "../components/HomePage";
import Footer from "../components/Footer";

// const query = new URLSearchParams(window.location.search);
// const lang = query.get('lang') ? query.get('lang') : 'en';
// console.log(lang)
const lang = 'de'

const IndexPage = () => {
  const [ userLanguage, setUserLanguage ] = useState (lang);

  useEffect(() => {
    localStorage.getItem('lang') ? setUserLanguage(localStorage.getItem('lang')) : setUserLanguage(lang);
  }, [])

  return (
    <Container fluid 
    style={{
      backgroundColor: "#1a1718",
      marginBottom: '0',
      maxWidth: '100%',
      paddingRight: '0',
      paddingLeft: '0'
      }}>
      <NavBar setLanguage={setUserLanguage} userLanguage={userLanguage} />
      <HomePage userLanguage={userLanguage} />
      <Footer />
    </Container>
  )
}

export default IndexPage
