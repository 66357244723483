import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Slider from "react-slick";

import gePlanning1 from "../images/Homepage/gePlanning1.png";
import gePlanning2 from "../images/Homepage/gePlanning2.png";
import srvMarriage from "../images/Homepage/Service_Marriage.png";
import srvCorporate from "../images/Homepage/Service_Corporate.png";
import srvCelebrate from "../images/Homepage/Service_Celebrate.png";

import jsonData from "./Contents/HomePageContent.en.json";

var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Header = ({ userLanguage }) => (
  <Container
    fluid
    style={{
      marginBottom: "0",
      maxWidth: "100%",
      paddingRight: "0",
      paddingLeft: "0",
    }}
  >
    <div id="hero">
      <div className="hero-content py-2" data-aos="fade-up">
        <h2 className="font-effect-anaglyph my-2">Golden Event</h2>
        <h4 className="font-effect-anaglyph">Altinsaray</h4>
        <h4 className="font-effect-anaglyph">&</h4>
        <h4 className="font-effect-anaglyph mb-3">Gümüssaray</h4>
      </div>
    </div>

    <div id="portfolio">
      <Container
        style={{
          // maxWidth: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div className="about-short-grid">
          <div className="p-3 d-none d-md-block item-1">
            <img src={gePlanning1} />
          </div>
          <div className="p-3 item-2">

          <div className="portfolio-description">
              <h2>
                {userLanguage === "en"
                  ? jsonData.engAbout.title
                  : userLanguage === "de"
                  ? jsonData.deAbout.title
                  : jsonData.trAbout.title}
              </h2>
              {userLanguage === "en"
                ? jsonData.engAbout.paragraphs.map((paragraph) => (
                    <p key={paragraph}>{paragraph}</p>
                  ))
                : userLanguage === "de"
                ? jsonData.deAbout.paragraphs.map((paragraph) => (
                    <p key={paragraph}>{paragraph}</p>
                  ))
                : jsonData.trAbout.paragraphs.map((paragraph) => (
                    <p key={paragraph}>{paragraph}</p>
                  ))}
            </div>


          </div>
          <div className="p-3 item-3">
            <img src={gePlanning2} />
          </div>
        </div>

      </Container>
    </div>

    <div id="service">
      <div className="mt-4 service-header">
        <h2>
          {userLanguage === "en"
            ? jsonData.engNav.services
            : userLanguage === "de"
            ? jsonData.deNav.services
            : jsonData.trNav.services}
        </h2>
      </div>
      <Container
        style={{
          maxWidth: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Row className="row-style justify-content-md-center">
          <Col md={4} className="p-2">
            <div className="service-description">
              <img
                src={srvMarriage}
                width="100%"
                height="100%"
                className="d-inline-block"
                alt="marriage ceremony image"
              />
              <a href="/Marriage">
                <h4>
                  {userLanguage === "en"
                    ? jsonData.engNav.marrigae
                    : userLanguage === "de"
                    ? jsonData.deNav.marrigae
                    : jsonData.trNav.marrigae}
                </h4>
              </a>
            </div>
          </Col>
          <Col md={4} className="p-2">
            <div className="service-description">
              <img
                src={srvCelebrate}
                width="100%"
                height="100%"
                className="d-inline-block"
                alt="selebrate image"
              />
              <a href="/Celebrate-Privately">
                <h4>
                  {userLanguage === "en"
                    ? jsonData.engNav.private
                    : userLanguage === "de"
                    ? jsonData.deNav.private
                    : jsonData.trNav.private}
                </h4>
              </a>
            </div>
          </Col>
          <Col md={4} className="p-2">
            <div className="service-description">
              <img
                src={srvCorporate}
                width="100%"
                height="100%"
                className="d-inline-block"
                alt="corporate event image"
              />
              <a href="/Corporate-Events">
                <h4>
                  {userLanguage === "en"
                    ? jsonData.engNav.corporate
                    : userLanguage === "de"
                    ? jsonData.deNav.corporate
                    : jsonData.trNav.corporate}
                </h4>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

    <div id="clients">
      <div className="clients-header">
        <h2>{jsonData.services[userLanguage].header}</h2>
      </div>
      <div className="px-5 text-center mt-3">
        <ul>
          {jsonData.services[userLanguage].offerings.map((offering) => (
            <li key={offering} className="mt-2">
              {offering}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </Container>
);

export default Header;
